<template>
  <div>
    <el-upload
      ref="upload"
      :class="{ uoloadSty: true, hide_box: upload_btn}"
      action=""
      list-type="picture-card"
      :file-list="fileList"
      file="file"
      :http-request="handleHttpRequest"
      :on-success="uploadSuccess"
      :beforeUpload="beforeSuccess"
    >
      <!--因为数据需要回显所以使用次方法-->
      <i slot="default" class="el-icon-plus"></i>
      <div slot="file" slot-scope="{file}">
        <el-progress type="circle" v-if="progressShow" :percentage="schedule"></el-progress>
        <img
          class="el-upload-list__item-thumbnail"
          :src="imgUrl" alt=""
          v-if="uploadType == 'img'"
        >
        <img v-if="uploadType == 'zip'" src="@/assets/images/yasuo.png" style="width: 146px;height: 146px;">
        <img v-if="uploadType != 'zip'&&uploadType != 'img'" src="@/assets/images/dashboard-nodata.png"
             style="width: 146px;height: 146px;">
        <span class="el-upload-list__item-actions">
        <span
         v-if="showView"
          class="el-upload-list__item-preview"
          @click="handlePictureCardPreview(file)"
        >
          <i class="el-icon-zoom-in"></i>
        </span>
          <!--        <span-->
          <!--          v-if="!readOnly"-->
          <!--          class="el-upload-list__item-delete"-->
          <!--          @click="rowDownload(file)"-->
          <!--        >-->
          <!--          <i class="el-icon-download"></i>-->
          <!--        </span>-->
        <span
          v-if="!readOnly"
          class="el-upload-list__item-delete"
          @click="handleRemove(file)"
        >
          <i class="el-icon-delete"></i>
        </span>
      </span>
      </div>
      <span v-if="fileName">{{ fileName }}</span>
      <div class="el-upload__tip" slot="tip">{{ fileName }}</div>
    </el-upload>
    <fileView ref="fileView" title="附件预览"></fileView>
  </div>
</template>

<script>
import {initTask, merge, preSignUrl, taskInfo} from "@/api/resource/attach";
import ServerNameEnum from "@/util/ServerNameEnum";
import Queue from "promise-queue-plus";
import md5 from "@/util/md5";
import website from "@/config/website";

export default {
  name: "singleFIleUpload",
  props: {
    echo: {
      type: String,
      default: 'no'
    },
    accept: {
      type: String,
      default: '*'
    },
    fileSize: {
      type: Number,
      default: null
    },
    beforeUpload: {
      type: Function,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    showView: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      upload_btn: false,
      fileList: [],
      fileName: '',
      tableData: [],
      uploadType: '',
      echo: "no",
      imgUrl: '',
      schedule: 0,
      fileUploadChunkQueue: {},
      progressShow:false
    }
  },
  methods: {
    async getTaskInfo(file) {
      if (file.size == 0)return false
      let task;
      const identifier = await md5(file)
      let res = await taskInfo(identifier)
      if (res.data.code === 200) {
        task = res.data.data
        if (Object.keys(task).length < 1) {
          let initTaskData = {
            'identifier': identifier,
            'fileName': file.name,
            'totalSize': file.size,
            'chunkSize': 5 * 1024 * 1024
          }
          let initTaskRes = await initTask(initTaskData)
          if (initTaskRes.data.code == 200) {
            task = initTaskRes.data.data
          } else {
            this.$message.error({
              title: '文件上传错误',
              message: initTaskRes.data.msg
            })
          }
        }
      } else {
        this.$message.error({
          title: '文件上传错误',
          message: res.data.msg
        })
      }
      return task
    },

    async handleHttpRequest(options) {
      const file = options.file
      const task = await this.getTaskInfo(options.file)
      console.log("taskRecordtaskRecordtaskRecord",options.file)
      if (task) {
        const {finished, path, taskRecord} = task
        const {identifier, id, attachId} = taskRecord
        if (finished) {
          this.tableData.find(item => item.uid == file.uid).schedule = "100%"
          this.schedule = 100
          this.$forceUpdate()
          return {'id': id, 'uid': file.uid, 'path': path, 'attachId': attachId}
        } else {
          const errorList = await this.handleUpload(file, taskRecord, options)
          if (errorList.length > 0) {
            this.$message.error({
              title: '文件上传错误',
              message: '部分分片上次失败，请尝试重新上传文件'
            })
            return;
          }
          const mergeRes = await merge(identifier)
          let code = mergeRes.data.code
          let msg = mergeRes.data.msg
          if (code === 200) {
            return {'id': id, 'uid': file.uid, 'path': path, 'attachId': attachId}
          } else {
            this.$message.error({
              title: '文件上传错误',
              message: msg
            })
          }
        }
      } else {
        this.$message.warning({
          message: '不允许上传空文件'
        })
        this.handleRemove(options.file)
      }
    },
    async handleUpload(file, taskRecord, options) {

      let lastUploadedSize = 0; // 上次断点续传时上传的总大小
      let uploadedSize = 0 // 已上传的大小
      const totalSize = file.size || 0 // 文件总大小
      let startMs = new Date().getTime(); // 开始上传的时间
      const {exitPartList, chunkSize, chunkNum, identifier} = taskRecord

      // 获取从开始上传到现在的平均速度（byte/s）
      const getSpeed = () => {
        // 已上传的总大小 - 上次上传的总大小（断点续传）= 本次上传的总大小（byte）
        const intervalSize = uploadedSize - lastUploadedSize
        const nowMs = new Date().getTime()
        // 时间间隔（s）
        const intervalTime = (nowMs - startMs) / 1000
        return intervalSize / intervalTime
      }

      const uploadNext = async (partNumber) => {
        const start = new Number(chunkSize) * (partNumber - 1)
        const end = start + new Number(chunkSize)
        const blob = file.slice(start, end)
        const res = await preSignUrl(identifier, partNumber)
        let code = res.data.code
        let data = res.data.data
        if (code === 200 && data) {
          await axios.request({
            url: data,
            method: 'PUT',
            data: blob,
            headers: {'Content-Type': 'application/octet-stream'}
          })
          return Promise.resolve({partNumber: partNumber, uploadedSize: blob.size})
        }
        return Promise.reject(`分片${partNumber}， 获取上传地址失败`)
      }

      /**
       * 更新上传进度
       * @param increment 为已上传的进度增加的字节量
       */
      const updateProcess = (increment) => {
        increment = new Number(increment)
        const {onProgress} = options
        let factor = 1000; // 每次增加1000 byte
        let from = 0;
        // 通过循环一点一点的增加进度
        while (from <= increment) {
          from += factor
          uploadedSize += factor
          const percent = Math.round(uploadedSize / totalSize * 100).toFixed(2);
          onProgress({percent: percent})
        }

        const speed = getSpeed();
        const remainingTime = speed != 0 ? Math.ceil((totalSize - uploadedSize) / speed) + 's' : '未知'
        if (!this.multiple) this.schedule = parseInt(uploadedSize / totalSize * 100) > 100 ? 100 : parseInt(uploadedSize / totalSize * 100)
        if (this.multiple) this.tableData.find(item => item.uid == file.uid).schedule = parseInt(uploadedSize / totalSize * 100) + "%"
        this.$forceUpdate()
        console.log('剩余大小：', (totalSize - uploadedSize) / 1024 / 1024, 'mb');
        console.log('进度：', parseInt(uploadedSize / totalSize * 100), "%");
        console.log('当前速度：', (speed / 1024 / 1024).toFixed(2), 'mbps');
        console.log('预计完成：', remainingTime);
      }


      return new Promise(resolve => {
        const failArr = [];
        const queue = Queue(10, {
          "retry": 3,               //Number of retries
          "retryIsJump": false,     //retry now?
          "workReject": function (reason, queue) {
            failArr.push(reason)
          },
          "queueEnd": function (queue) {
            resolve(failArr);
          }
        })
        this.fileUploadChunkQueue[file.uid] = queue
        for (let partNumber = 1; partNumber <= chunkNum; partNumber++) {
          const exitPart = (exitPartList || []).find(exitPart => exitPart.partNumber == partNumber)
          if (exitPart) {
            // 分片已上传完成，累计到上传完成的总额中,同时记录一下上次断点上传的大小，用于计算上传速度
            lastUploadedSize += new Number(exitPart.size)
            updateProcess(exitPart.size)
          } else {
            queue.push(() => uploadNext(partNumber).then(res => {
              // 单片文件上传完成再更新上传进度
              updateProcess(res.uploadedSize)
            }))
          }
        }
        if (queue.getLength() == 0) {
          // 所有分片都上传完，但未合并，直接return出去，进行合并操作
          resolve(failArr);
          return;
        }
        queue.start()
      })
    },
    beforeSuccess(res) {
      this.schedule = 0;
      this.progressShow = true;
      if (this.beforeUpload != null) {
        var beforeUpload = this.beforeUpload(res);
        if (!beforeUpload) {
          return false;
        }
      }

      this.$nextTick(() => {
        let fileName = res.name
        this.fileName = res.name
        let pos = fileName.lastIndexOf(".")
        let lastName = fileName.substring(pos, fileName.length).toLowerCase()

        let arr = Array.from(this.accept.split(','))
        if (this.accept && !arr.includes(lastName)) {
          this.$message.warning("请选择" + this.accept + '类型文件')
          return
        }
        // const isSize = file.size/ 1024 / 1024 // 文件大小
        //因为使用file报错
        const isSize = res.size / 1024 / 1024 // 文件大小
        if (this.fileSize && isSize > this.fileSize) {
          this.$message.warning(`上传文件大小不能超过${this.fileSize}MB!`);
          return false;
        }

        this.upload_btn = true
        if (res.type == 'image/jpeg') {
          this.uploadType = 'img'
        } else {
          this.uploadType = 'zip'
          this.fileName = res.name
        }
        //先暂时使用统一图片
        this.uploadType = 'video'
        this.tableData.push(res)
      })
    },
    /**
     * 回显调用此方法
     * @param res
     */
    assignedPicture(res) {
      this.fileList = [];
      this.fileList = [{
        name: res.name,
        url: res.link,
        id: res.id,
      }]
      this.uploadType = 'video'
      this.echo = "yes"
      this.upload_btn = true
    },
    uploadSuccess(file, res) {
      this.imgUrl = file.path
      this.tableData.find(item => item.uid == file.uid).id = file.id
      this.$emit('fileIds', res)
      this.fileTaskUrl = process.env.VUE_APP_BASE_KKFILEVIEWURL + this.imgUrl;
      console.log(this.fileTaskUrl, "===this.fileUrl===")
      axios.get(this.fileTaskUrl)
    },
    showFile(file) {
      this.$refs.fileView.showFile(file)
    },
    handlePictureCardPreview(file) {
      console.log("789--------------", file)
      this.dialogImageUrl = file.url;
      if (!file.url.includes("blob")) {
        this.showFile(file.url);
      } else {
        this.showFile(file.response.path);

      }
    },
    handleRemove(row) {
      this.tableData = []
      this.$refs.upload.uploadFiles = []
      this.imgUrl = ''
      this.upload_btn = false
      const queueObject = this.fileUploadChunkQueue[row.uid]
      if (queueObject) {
        queueObject.stop()
      }
      this.fileName = ''
      if (!row.url.includes("blob")) {
        row.attachId = row.id
        this.$emit("handleRemove", row)
      } else {
        this.$emit("handleRemove", row.response)
      }
    },
    rowDownload(row) {
      let fileId = row.id
      if (!this.multiple) {
        fileId = this.tableData[0].id
      }
      if (!fileId) {
        this.$message.error("文件未上传成功")
      }
      axios({
        url: ServerNameEnum.SERVER_RESOURCE_ + '/file/download/' + fileId,
        method: 'GET',
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          this.fileDownloadQueue[fileId] = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          console.log(this.fileDownloadQueue)
        }
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', row.name);
        document.body.appendChild(link);
        link.click();
        this.downloading = false;
        this.progress = 0;
      });
    },
  }
}
</script>

<style scoped>
.hide_box /deep/ .el-upload--picture-card {
  display: none;
}
</style>
