var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-upload",
        {
          ref: "upload",
          class: { uoloadSty: true, hide_box: _vm.upload_btn },
          attrs: {
            action: "",
            "list-type": "picture-card",
            "file-list": _vm.fileList,
            file: "file",
            "http-request": _vm.handleHttpRequest,
            "on-success": _vm.uploadSuccess,
            beforeUpload: _vm.beforeSuccess,
          },
          scopedSlots: _vm._u([
            {
              key: "file",
              fn: function ({ file }) {
                return _c(
                  "div",
                  {},
                  [
                    _vm.progressShow
                      ? _c("el-progress", {
                          attrs: { type: "circle", percentage: _vm.schedule },
                        })
                      : _vm._e(),
                    _vm.uploadType == "img"
                      ? _c("img", {
                          staticClass: "el-upload-list__item-thumbnail",
                          attrs: { src: _vm.imgUrl, alt: "" },
                        })
                      : _vm._e(),
                    _vm.uploadType == "zip"
                      ? _c("img", {
                          staticStyle: { width: "146px", height: "146px" },
                          attrs: { src: require("@/assets/images/yasuo.png") },
                        })
                      : _vm._e(),
                    _vm.uploadType != "zip" && _vm.uploadType != "img"
                      ? _c("img", {
                          staticStyle: { width: "146px", height: "146px" },
                          attrs: {
                            src: require("@/assets/images/dashboard-nodata.png"),
                          },
                        })
                      : _vm._e(),
                    _c(
                      "span",
                      { staticClass: "el-upload-list__item-actions" },
                      [
                        _vm.showView
                          ? _c(
                              "span",
                              {
                                staticClass: "el-upload-list__item-preview",
                                on: {
                                  click: function ($event) {
                                    return _vm.handlePictureCardPreview(file)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-zoom-in" })]
                            )
                          : _vm._e(),
                        !_vm.readOnly
                          ? _c(
                              "span",
                              {
                                staticClass: "el-upload-list__item-delete",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleRemove(file)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-delete" })]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ],
                  1
                )
              },
            },
          ]),
        },
        [
          _c("i", {
            staticClass: "el-icon-plus",
            attrs: { slot: "default" },
            slot: "default",
          }),
          _vm.fileName ? _c("span", [_vm._v(_vm._s(_vm.fileName))]) : _vm._e(),
          _c(
            "div",
            {
              staticClass: "el-upload__tip",
              attrs: { slot: "tip" },
              slot: "tip",
            },
            [_vm._v(_vm._s(_vm.fileName))]
          ),
        ]
      ),
      _c("fileView", { ref: "fileView", attrs: { title: "附件预览" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }